import React, { useState, useEffect } from 'react';

const Testimonials = () => {
  const testimonials = [
    {
      text: "My business was going from bad to worse before I met TrimlyXpert. I struggled with client management and scheduling, but once I started using their platform, I saw significant improvement. Not only did my scheduling become seamless, but their automated reminders helped reduce no-shows. I now have a better handle on my appointments and can focus on growing my business.",
      author: "Ayush Pandey",
    },
    {
      text: "Working with TrimlyXpert has been a game-changer for my barbershop. They understand your needs and deliver the best solutions. Their platform helped us streamline everything from booking to customer relationship management. I saw a rise in customer loyalty and even new walk-ins who found us through TrimlyXpert.",
      author: "Kamal Joshi",
    },
    {
      text: "The platform improved my workflow significantly by giving me a simple way to keep track of everything. The team is fantastic and super responsive whenever I have questions or need help with any features.",
      author: "Anmol Tyagi",
    },
    {
      text: "I was amazed by how quickly TrimlyXpert’s team adapted to our needs and delivered such great results. They worked closely with us to customize the platform for our salon, and the outcome was beyond expectations. I’ve never had such an easy time managing my business.",
      author: "Akshay Yadav",
    },
    {
      text: "Since using TrimlyXpert, our online visibility has increased drastically. Their tools have made it easier for us to reach new clients and provide a better experience for our existing ones. The automated appointment system has been a lifesaver for our busy barbershop, and we’ve seen higher retention rates as a result.",
      author: "Gaurav Ranawat",
    },
    {
      text: "TrimlyXpert’s services helped me get my business back on track. After struggling with managing appointments and clients for months, their platform simplified everything. Now, I can focus on providing the best service to my customers instead of worrying about the administrative side of things.",
      author: "Neetu Tiwari",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 6000); 

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <div className="container">
      <section className="testimonials">
        <h2>What our clients are saying about us</h2>
        <div className="testimonial">
          <p>"{testimonials[currentIndex].text}"</p>
          <p>- {testimonials[currentIndex].author}</p>
        </div>
      </section>
      <footer>
        {/* <p>© 2024 TrimlyXpert. All rights reserved.</p> */}
      </footer>
    </div>
  );
};

export default Testimonials;
