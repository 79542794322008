import React from 'react';

function Privacy() {
  return (
    <div className="privacy-policy-container">
      <div className="content-wrapper">
        <h1 className="section-title">Privacy Policy</h1>
        <p className="effective-date">Effective Date: 20/10/2024</p>

        <p>
          TrimlyXpert ("we", "our", "us") operates the TrimlyXpert mobile application and website (
          <a href="https://trimlyxpert.ryientsoft.com/" target="_blank" rel="noopener noreferrer">
          www.trimlyxpert.ryientsoft.com
          </a>
          ). We provide barber services, including haircuts, beard grooming, styling, and other grooming solutions. 
          We are committed to protecting your personal data and ensuring transparency about how we collect, use, 
          and safeguard your information. This Privacy Policy applies to all users of our mobile app and services.
        </p>

        <h2>1. Information We Collect</h2>
        <p>We collect the following types of information when you use our app and services:</p>

        <h3>Personal Information:</h3>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
        </ul>

        <h3>Usage Data:</h3>
        <ul>
          <li>Date and time of appointments</li>
          <li>Service preferences (e.g., haircut style or grooming preferences)</li>
          <li>Device information (e.g., mobile device model, OS version)</li>
          <li>IP address and browser type (when accessing the website)</li>
          <li>Location data (to show nearby barbers)</li>
        </ul>

        <h3>Location Data:</h3>
        <p>
          Our mobile app collects precise location data from your mobile device to show nearby barbers and salons 
          for haircuts or grooming services. You can disable location sharing via your device settings, but this 
          may limit the app's functionality in recommending services.
        </p>

        <h2>2. How We Use Your Information</h2>
        <p>We use the information we collect to:</p>
        <ul>
          <li>Provide and enhance our barber services</li>
          <li>Facilitate booking appointments with barbers</li>
          <li>Send appointment reminders and updates via SMS or email</li>
          <li>Show nearby barbers and personalized service suggestions based on your preferences</li>
          <li>Process payments and manage your transactions</li>
          <li>Ensure compliance with legal and regulatory requirements</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>We do not sell or rent your personal information to third parties. However, we may share your information with:</p>
        <ul>
          <li>
            <strong>Service Providers:</strong> Barbers, salons, and third-party service providers
            who need the information to fulfill your service bookings.
          </li>
          <li>
            <strong>Legal Requirements:</strong> If required by law, to comply with legal obligations, 
            or to protect our rights and users.
          </li>
        </ul>

        <h2>4. Data Security</h2>
        <p>
          We take reasonable steps to protect your personal information from unauthorized access or misuse. 
          However, no online platform or mobile app is completely secure. While we strive to protect your 
          information, we cannot guarantee absolute security.
        </p>

        <h2>5. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access and review the personal information we hold about you</li>
          <li>Request corrections or updates to your personal data</li>
          <li>Request deletion of your personal data, subject to certain conditions</li>
          <li>Withdraw consent for data processing where applicable</li>
        </ul>
        <p>To exercise any of these rights, please contact us at helpdesk@TrimlyXpert.com.</p>

        <h2>6. Cookies and Tracking Technologies</h2>
        <p>
          We may use cookies and similar technologies to enhance your experience on our website and app. 
          You can set your browser to refuse cookies, but some features of the website or app may not function 
          properly without them.
        </p>

        <h2>7. Changes to this Privacy Policy</h2>
        <p>
          We may update our Privacy Policy periodically to reflect changes in our services or legal requirements. 
          Any updates will be posted on this page with the updated effective date.
        </p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <address>
          Email: <a href="mailto:helpdesk@TrimlyXpert.com">helpdesk@TrimlyXpert.com</a> <br />
          Website: <a href="https://trimlyxpert.ryientsoft.com/">www.trimlyxpert.ryientsoft.com</a> <br />
        </address>
      </div>
    </div>
  );
}

export default Privacy;