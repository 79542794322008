import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Header';
import HeroSection from './HeroSection';
import Features from './Features';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import Footer from './Footer';
import './App.css';
import Privacy from './Privacy';
import CaseStudies from './CaseStudies';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes> 
          <Route path="/" element={<HeroSection />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/casestudies" element={<CaseStudies />} />
          <Route path="/privacy" element={<Privacy />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;