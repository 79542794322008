import React, { useState } from 'react';

const CaseStudy = ({ title, content, fullContent }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="case-study-item">
      <h2>{title}</h2>
      <p>
        {isExpanded ? fullContent : content} 
      </p>
      <button className="read-more-btn" onClick={toggleReadMore}>
        {isExpanded ? 'Show Less' : 'Read More'}
      </button>
    </div>
  );
};

const CaseStudies = () => {
  return (
    <div className="case-studies-section">
      <h1>Discover How TrimlyXpert Transformed Lives!</h1>
      <div className="case-studies-grid">
        <CaseStudy
          title="Simplifying Grooming for Busy Professionals"
          content="Ayush, a busy software engineer, found it hard to maintain his grooming routine due to his demanding work schedule..."
          fullContent="Ayush, a busy software engineer, found it hard to maintain his grooming routine due to his demanding work schedule. Using TrimlyXpert, he managed to book appointments at his convenience, fitting grooming seamlessly into his life."
        />
        
        <CaseStudy
          title="Growing Client Loyalty for Barbershops"
          content="Raj's barbershop had customer retention issues due to missed appointments. By using TrimlyXpert, clients could easily manage their bookings..."
          fullContent="Raj's barbershop had customer retention issues due to missed appointments. By using TrimlyXpert, clients could easily manage their bookings and received reminders, increasing retention by 30%."
        />

        <CaseStudy
          title="Helping Fitness Enthusiasts Stay Sharp"
          content="Vikas, a fitness trainer, needed quick and reliable grooming services to fit around his workout schedule. TrimlyXpert enabled him to book..."
          fullContent="Vikas, a fitness trainer, needed quick and reliable grooming services to fit around his workout schedule. TrimlyXpert enabled him to book appointments that aligned with his fitness routine."
        />

        <CaseStudy
          title="Empowering Freelance Barbers with a Digital Platform"
          content="Sakshi, a freelance barber, found it difficult to grow her client base. By joining TrimlyXpert, she increased her bookings by 40%..."
          fullContent="Sakshi, a freelance barber, found it difficult to grow her client base. By joining TrimlyXpert, she increased her bookings by 40%, connecting with clients who appreciated the ease of booking through the app."
        />

        <CaseStudy
          title="Enhancing Grooming for Special Occasions"
          content="Rohan, a groom-to-be, used TrimlyXpert to schedule grooming services for him and his groomsmen before the wedding..."
          fullContent="Rohan, a groom-to-be, used TrimlyXpert to schedule grooming services for him and his groomsmen before the wedding. The app helped them look their best without the hassle of last-minute appointments."
        />

        <CaseStudy
          title="Scaling Barber Services for Large Events"
          content="TrimlyXpert played a crucial role in helping Kunal, a barber managing services for large corporate events, scale his offerings and..."
          fullContent="TrimlyXpert played a crucial role in helping Kunal, a barber managing services for large corporate events, scale his offerings and seamlessly manage multiple bookings. The app's scheduling features allowed him to handle large volumes of clients while maintaining quality and timing."
        />
      </div>
    </div>
  );
};

export default CaseStudies;