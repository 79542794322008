import React from 'react';
import { Link } from "react-router-dom";


const Footer = () => {
  return  (
    <footer className="footer">
      <div className="footer-nav">
      <Link to="/">About Us</Link>
        <Link to="/features">Features</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/casestudies">Case Studies</Link>
        <Link to="/privacy">Privacy</Link>
      </div>
      <p>© 2024 TrimlyXpert. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;