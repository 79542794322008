import React from 'react';

const Pricing = () => {
  return (
    <section className="pricing">
      <h2>Flexible, Transparent Pricing for Your Barbershop</h2>
      <p>Choose the plan that best fits your needs and take your grooming business to the next level!</p>
      
      <div className="pricing-options">
        <div className="pricing-option starter">
          <h3>Basic Grooming Plan</h3>
          <p className="price">₹650/month</p>
          <ul>
            <li>Appointment booking system</li>
            <li>Basic customer analytics</li>
            <li>1 staff login</li>
          </ul>
          <button>Choose Plan</button>
        </div>

        <div className="pricing-option pro">
          <h3>Professional Plan</h3>
          <p className="price">₹1500/month</p>
          <ul>
            <li>Advanced scheduling & booking tools</li>
            <li>Customer feedback tracking</li>
            <li>Up to 5 staff logins</li>
          </ul>
          <button>Choose Plan</button>
        </div>

        <div className="pricing-option enterprise">
          <h3>Elite Barbershop Plan</h3>
          <p className="price">₹3500/month</p>
          <ul>
            <li>Unlimited appointments & bookings</li>
            <li>Dedicated account manager</li>
            <li>Unlimited staff logins</li>
          </ul>
          <button>Choose Plan</button>
        </div>
      </div>
    </section>
  );
};

export default Pricing;