import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return  (
    <header className="header">
      <Link to="/" style={{ textDecoration: 'none', color: 'white' , fontSize: 26}}>
          TrimlyXpert
        </Link>

      <nav className="nav">
        <Link to="/">About Us</Link>
        <Link to="/features">Features</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/testimonials">Testimonials</Link>
        <Link to="/casestudies">Case Studies</Link>

      </nav>
      <div className="auth-buttons">
        <button className="sign-in">Sign In</button>
        <button className="sign-up">Sign Up</button>
      </div>
    </header>
  );
};

export default Header;